<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard | Marketing</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">SMS Templates</a></li>
    </ol>
    <h4 class="page-header">SMS TEMPLATES</h4>
    <panel variant="panel-default">
      <vue-good-table :columns="columns" :lineNumbers="true" :pagination-options="{ enabled: true, position: 'bottom' }" :rows="templates" :search-options="{ enabled: true }">
        <div slot="table-actions">
          <button class="btn btn-sm btn-black" @click.prevent="templateModal(null)">
            <i class="fa fa-plus"></i> Create New
          </button>
          <download-excel v-if="templates.length" :data="templates" :title="'Marketing Groups'" class="btn btn-primary" name="Templates.xls">
            <i class="fa fa-file-excel"></i> Export Excel
          </download-excel>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.label === 'Action'">
            <button class="btn btn-sm btn-info" type="button" @click="templateModal(props.row)">Edit</button>
          </span>
        </template>
      </vue-good-table>
    </panel>
    <modal :show-modal="showModal" @close="showModal = false">
      <template v-slot:header>
        <h5><span v-if="template">Edit </span><span v-else>Create</span> Template</h5>
      </template>
      <form @submit.prevent="save">
        <div v-html="$error.handle(error)" />
        <div class="row">
          <div class="form-group col-md-12">
            <label>Name:</label>
            <input  v-model="name" type="text" class="form-control" >
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label>Message:</label>
            <textarea   v-model="message" class="form-control" cols="30" rows="10" ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <button class="btn btn-primary w-100">Save</button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Message",
          field: "message",
        },
        {
          label: "Action",
          field: "id",
        }
      ],
      loading: true,
      showModal: false,
      template: null,
      name: '',
      message: '',
      error: '',
      templates: []
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/sms-templates').then(response => {
        let tlist = response.data.data;
        this.templates = tlist.map(x => {
          return {'id': x.id, 'name': x.name,'message': x.message}
        });
      });
    },
    save() {
      if (this.template) {
        this.edit();
        return;
      }
      this.$axios.post('/api/v1/sms-templates', {'name': this.name,'message': this.message}).then(() => {
        this.showModal = false;
        this.fetch();
        this.name = '';
        this.message = '';
        this.template = null;
        this.$toastr.s('Template created successfully!');
      });
    },
    edit() {
      this.$axios.put('/api/v1/sms-templates/' + this.template.id, {'name': this.name,'message': this.message}).then(() => {
        this.showModal = false;
        this.name = '';
        this.template = null;
        const index = this.templates.findIndex(d => d.id === this.template.id);
        if (index !== -1) this.templates[index] = this.template;
        this.$toastr.s("Template updated successfully")
      }).catch(error => {
        this.error = error.response;
      });
    },
    templateModal(template) {
      if (template != null) {
        this.template = template;
        this.name = template.name;
        this.message = template.message;
      } else {
        this.template = null;
      }
      this.showModal = true;
    }
  }
}
</script>